import React from "react";
import { formatByCurrency, formatterConfigDefault } from "@anefi/utils";
import { Image } from "artisn-ui-react";
import { CDNImage } from "artisn/types";
import { useRouter } from "next/router";

import Styles from "./RecordProductRow.styles";
import { RecordProductRowProps as Props } from "./RecordProductRow.types";
import { imagesConfig } from "./RecordProductRow.helpers";

const RecordProductRow: React.FC<Props> = props => {
  const { push } = useRouter();
  const { balanceProduct } = props;
  const { balance, product } = balanceProduct;
  const { name, images, attributes, productId } = product;
  const { externalId } = attributes;
  const { capital, interestBalance, totalBalance } = balance;
  const productUrlImage = images?.find(image => image.name === "product_logo");

  const colorPicker = (value: number) => {
    if (value < 0) {
      return "RecordProductRow__record__red";
    }
    return "RecordProductRow__record__green";
  };

  return (
    <Styles
      className="RecordProductRow"
      onClick={() => {
        push(`/products/${productId}/${name}`);
      }}
    >
      <div className="RecordProductRow__company">
        <div className="RecordProductRow__image">
          {productUrlImage ? (
            <Image
              image={productUrlImage as CDNImage}
              config={imagesConfig}
              alt="sdn image"
              placeholder="skeleton"
            />
          ) : null}
        </div>
        <div className="RecordProductRow__amount">
          <p className="RecordProductRow__name">{name}</p>
          <p className="RecordProductRow__plan">{externalId}</p>
        </div>
      </div>
      <div className="RecordProductRow__record">
        <p className="RecordProductRow__record__black">
          {formatByCurrency(+capital, formatterConfigDefault)}
        </p>
        <p className={colorPicker(+interestBalance)}>
          {formatByCurrency(+interestBalance, formatterConfigDefault)}
        </p>
        <p className="RecordProductRow__record__black">
          {formatByCurrency(+totalBalance, formatterConfigDefault)}
        </p>
      </div>
    </Styles>
  );
};

RecordProductRow.defaultProps = {};

export default RecordProductRow;
