import React from "react";
import { useFetchProductBalances } from "@anefi/services";
import { useRouter } from "next/router";

import Styles from "./Record.styles";
import { RecordProps as Props } from "./Record.types";
import useAuth from "contexts/auth/auth.context.hooks";
import RecordProductRow from "../RecordProductRow/RecordProductRow";
import RecordProductRowPlaceholder from "../RecordProductRow/RecordProductRow.placeholder";
import EmptyState from "components/global/EmptyState/EmptyState";
import { ButtonOptions } from "components/global/EmptyState/EmptyState.types";
import ErrorState from "components/error/ErrorState/ErrorState";

import FinanceSvg from "../../../../../public/assets/images/finances.svg";

const Record: React.FC<Props> = props => {
  const { className } = props;
  const { push } = useRouter();
  const auth = useAuth();
  const { isAnonymous } = auth;
  const balanceProductResponse = useFetchProductBalances(auth);
  const { data: balanceProducts, status } = balanceProductResponse;
  const { error, refetch } = balanceProductResponse;

  const buttonOptions: ButtonOptions = {
    action: () => push("/products"),
    title: "Explorar productos",
    type: "BORDER",
    color: "primary-light"
  };

  const emptyNode = (
    <EmptyState
      icon={<FinanceSvg />}
      title="No has realizado ningúna inversión"
      message="Empieza a explorar nuestros productos para empezar a invertir."
      buttonOptions={buttonOptions}
    />
  );

  const renderStates = () => {
    if (status === "loading" || status === "idle") {
      return <RecordProductRowPlaceholder />;
    }

    if (status === "error") {
      const { message } = error ?? {};
      if (message === "No valid client") {
        return emptyNode;
      }
      return (
        <ErrorState
          title="¡Oops! hubo un problema al cargar las inversiones"
          subtitle="Al parecer algo falló al cargar las inversiones, intenta recargando la pantalla."
          buttonText="Intentar nuevamente"
          showButton
          onButtonClick={refetch}
        />
      );
    }

    if (status === "success") {
      if (balanceProducts.length === 0) {
        return emptyNode;
      }
      return (
        <>
          <div className="Record__titles">
            <p>Inversión</p>
            <p>Rendimiento ($)</p>
            <p>Total</p>
          </div>
          <div className="Record__rows">
            {balanceProducts.map((balanceProduct, index) => (
              <RecordProductRow key={index} balanceProduct={balanceProduct} />
            ))}
          </div>
        </>
      );
    }

    return null;
  };

  return (
    <Styles className={`Record ${className}`}>
      <h3 className="Record__title">Mis Inversiones</h3>
      {isAnonymous ? emptyNode : renderStates()}
    </Styles>
  );
};

Record.defaultProps = {
  className: ""
};

export default Record;
