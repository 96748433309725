import styled from "styled-components";

import { RecommendationsStyledProps as Props } from "./Recommendations.types";

const RecommendationsStyled = styled.div<Props>`
  .Recommendations {
    &__top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__title {
        color: var(--palette-black);
        font-size: 2.2rem;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__bottom {
      margin-top: 5.2rem;
    }

    &__button {
      margin-top: 3.2rem;
    }
  }
`;

export default RecommendationsStyled;
