import styled from "styled-components";

import { InvestmentDiagramStyledProps as Props } from "./InvestmentDiagram.types";
import { CONSTANTS } from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { desktop, mobile } = BREAKPOINTS;

const InvestmentDiagramStyled = styled.div<Props>`
  .InvestmentDiagram {
    &__update {
      font-size: 1.2rem;
      color: var(--palette-black);
    }

    &__greeting {
      padding-top: 1rem;
      font-weight: 500;
      font-size: 2.8rem;
    }

    &__button {
      gap: 2rem;

      &:hover {
        background-color: var(--palette-white);
        box-shadow: unset !important;
      }
    }

    &__container {
      background-color: var(--palette-white);
      margin-top: 2.4rem;
      border-radius: 1.2rem;
      box-shadow: 0 0 2rem rgba(17, 61, 32, 0.08);
      padding: 2.4rem;

      @media (max-width: ${desktop}px) {
        margin-top: 4.2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      @media (max-width: ${mobile}px) {
        padding: 2rem 1rem;
      }
    }

    &__startContainer {
      max-width: 40rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__phrase {
      color: var(--palette-primary);
      font-size: 2.8rem;
      font-weight: 600;
      line-height: 3.6rem;
      text-align: center;
    }

    &__author {
      color: var(--palette-grey-blue-s15-l80);
      font-size: 1.2rem;
      padding-top: 1.4rem;
    }

    &__investButton {
      margin-top: 2.4rem;
      width: 100%;
    }

    &__signIn {
      margin-top: 2.4rem;
      width: 100%;
    }

    &__totals {
      display: flex;
      gap: 1.6rem;

      @media (max-width: ${mobile}px) {
        flex-direction: column;
      }
    }

    &__total {
      color: var(--palette-primary);
      font-size: 4rem;
      font-weight: 600;
    }

    &__performance {
      @media (max-width: ${mobile}px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    &__title {
      color: var(--palette-gray-s0-l50);
      font-size: 1.2rem;
    }

    &__result {
      background-color: var(--palette-gray-s0-l95);
      padding: 0.5rem 0.8rem;
      border-radius: 3.6rem;
      color: var(--palette-secondary-s40-l45);
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: ${mobile}px) {
        max-width: 10rem;
      }
    }

    &__chart {
      max-width: 50rem;
    }

    &__placeholderNumber {
      width: 22rem;
      height: 4.6rem;
      border-radius: 1.8rem;
    }

    &__placeholderGraph {
      width: 40rem;
      height: 18rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      border-radius: 1.8rem;

      @media (max-width: ${mobile}px) {
        width: 27.8rem;
        height: 14rem;
      }
    }

    &__selector {
      display: flex;
      gap: 2.8rem;

      > p {
        color: var(--palette-primary);
        font-size: 1.6rem;
        font-weight: 600;
        margin-top: 2.8rem;
        padding: 0.52rem;

        &:hover {
          background-color: var(--palette-primary);
          color: var(--palette-white);
          border-radius: 4rem;
          cursor: pointer;
        }
      }
    }
  }
`;

export default InvestmentDiagramStyled;
