import { useStores } from "@anefi/contexts";
import { useCatalogues } from "@anefi/contexts";
import { useFetchCategoriesWithProducts } from "@anefi/services";
import router from "next/router";
import React, { useMemo } from "react";

import Styles from "./Recommendations.styles";
import { RecommendationsProps as Props } from "./Recommendations.types";
import Button from "components/global/Button/Button";
import RecommendationsSlider from "components/global/RecommendationsSlider/RecommendationsSlider";
import AnefiCard from "components/home/AnefiCard/AnefiCard";
import { CONSTANTS } from "config/constants";

import PigSVG from "../../../../../public/assets/images/anefi/pig.svg";

const { VENDOR_ID } = CONSTANTS;

const Recommendations: React.FC<Props> = props => {
  const { className } = props;
  const { push } = router;

  const { selectedStore } = useStores();
  const { selectedCatalogueId: catalogueId } = useCatalogues();
  const storeId = selectedStore?.storeId ?? 0;

  const categoriesResponse = useFetchCategoriesWithProducts({
    catalogueId,
    storeId,
    vendorId: VENDOR_ID,
    page: 1
  });

  const { data: categoriesPages, status, refetch } = categoriesResponse;

  const categoriesWithProductsArray = useMemo(() => {
    return categoriesPages?.pages.flatMap(page => page.data);
  }, [categoriesPages]);

  return (
    <Styles className={`Recommendations ${className}`}>
      <div className="Recommendations__top">
        <div className="Recommendations__top__title">Recomendaciones</div>
        <Button
          type="LINK"
          onClick={() => {
            push("/products");
          }}
        >
          Ver todos
        </Button>
      </div>
      <RecommendationsSlider
        items={categoriesWithProductsArray}
        status={status}
        refetch={refetch}
      />
      <AnefiCard
        founds
        title="Fondos"
        description="Tu dinero crece mientras ahorras"
        image={<PigSVG />}
        className="Recommendations__bottom"
        itemsProduct={categoriesWithProductsArray}
        status={status}
        refetchProducts={refetch}
      />
      <Button
        type="BORDER"
        onClick={() => {
          push("/products");
        }}
        className="Recommendations__button"
      >
        Ver todos los productos
      </Button>
    </Styles>
  );
};

Recommendations.defaultProps = {
  className: ""
};

export default Recommendations;
