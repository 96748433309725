import React, { useMemo, useState } from "react";
import router from "next/router";
import { VictoryLine, VictoryChart } from "victory";
import { VictoryScatter, VictoryTooltip } from "victory";
import { VictoryAxis, VictoryVoronoiContainer } from "victory";
import { useFetchDailyBalance } from "@anefi/services";
import { formatByCurrency, formatterCompactNotation } from "@anefi/utils";
import { formatterConfigDefault } from "@anefi/utils";

import Styles from "./InvestmentDiagram.styles";
import { InvestmentDiagramProps as Props } from "./InvestmentDiagram.types";
import Button from "components/global/Button/Button";
import ProfileHeader from "components/profile/ProfileHeader/ProfileHeader";
import useAuth from "contexts/auth/auth.context.hooks";
import { getBalancesLabel, getTickCount } from "./InvestmentDiagram.helpers";
import { axisStyle, lineStyle } from "./InvestmentDiagram.helpers";
import { scatterStyle } from "./InvestmentDiagram.helpers";
import { transformBalanceToGraphData } from "./InvestmentDiagram.helpers";
import ChartTimeButton from "./ChartTimeButton/ChartTimeButton";
import ErrorState from "components/error/ErrorState/ErrorState";

import MovementsSVG from "../../../../../public/assets/images/anefi/movements.svg";

const InvestmentDiagram: React.FC<Props> = props => {
  const { className } = props;
  const { push } = router;

  const [chartTime, setChartTime] = useState<number>(90);
  const auth = useAuth();
  const dailyBalancesResponse = useFetchDailyBalance(auth, chartTime);
  const { data: dailyBalances, status } = dailyBalancesResponse;
  const { error, refetch } = dailyBalancesResponse;
  const { isAnonymous } = auth;

  const data = useMemo(
    () => transformBalanceToGraphData(dailyBalances),
    [dailyBalances]
  );

  const tickCount = useMemo(() => getTickCount(dailyBalances), [dailyBalances]);
  const yLabel = useMemo(
    () => getBalancesLabel(dailyBalances),
    [dailyBalances]
  );

  const totalBalance = useMemo(
    () =>
      formatByCurrency(
        dailyBalances?.[dailyBalances?.length - 1]?.balance ?? 0,
        formatterConfigDefault
      ),
    [dailyBalances]
  );

  const emptyNode = (
    <div className="InvestmentDiagram__startContainer">
      <p className="InvestmentDiagram__phrase">
        “Invertir es incrementar las posibilidades de un buen futuro”
      </p>
      <p className="InvestmentDiagram__author">- Junior Finance.</p>
      <Button
        className="InvestmentDiagram__investButton"
        onClick={() => push("/products")}
      >
        Comenzar a invertir
      </Button>
      {isAnonymous ? (
        <Button
          className="InvestmentDiagram__signIn"
          type="BORDER"
          onClick={() => push("/login")}
        >
          Ingresar con tu cuenta
        </Button>
      ) : null}
    </div>
  );

  const renderState = () => {
    if (status === "loading" || status === "idle") {
      return (
        <>
          <div className="InvestmentDiagram__placeholderNumber loading-shine" />
          <div className="InvestmentDiagram__placeholderGraph loading-shine" />
        </>
      );
    }
    if (status === "error") {
      const { message } = error ?? {};
      if (message === "No valid client") {
        return emptyNode;
      }
      return (
        <ErrorState
          title="¡Oops! hubo un problema al cargar el balance"
          subtitle="Al parecer algo falló al cargar el balance, intenta recargando la pantalla."
          buttonText="Intentar nuevamente"
          showButton
          onButtonClick={refetch}
        />
      );
    }
    if (status === "success") {
      return totalBalance === "$0.00" ? (
        emptyNode
      ) : (
        <>
          <div className="InvestmentDiagram__totals">
            <div className="InvestmentDiagram__total">{totalBalance}</div>
            <div className="InvestmentDiagram__performance">
              <p className="InvestmentDiagram__title">Rendimiento</p>
              <p className="InvestmentDiagram__result">
                {formatByCurrency(
                  dailyBalances?.[dailyBalances?.length - 1]?.unitValue ?? 0,
                  formatterConfigDefault
                )}
              </p>
            </div>
          </div>
          <div className="InvestmentDiagram__chart">
            <VictoryChart
              width={400}
              height={200}
              domainPadding={{ y: 6 }}
              containerComponent={
                <VictoryVoronoiContainer
                  voronoiBlacklist={["line"]}
                  labels={({ datum }) =>
                    formatByCurrency(datum.y, formatterCompactNotation)
                  }
                  labelComponent={
                    <VictoryTooltip
                      centerOffset={{ x: 5 }}
                      constrainToVisibleArea
                    />
                  }
                />
              }
            >
              <VictoryScatter style={scatterStyle} size={5} data={data} />
              <VictoryLine
                name="line"
                data={data}
                x="x"
                y="y"
                style={lineStyle}
              />
              <VictoryAxis
                dependentAxis
                orientation="right"
                style={axisStyle}
                fixLabelOverlap
                tickCount={tickCount}
                tickValues={yLabel}
                tickFormat={amount =>
                  formatByCurrency(amount, formatterCompactNotation)
                }
              />
              <VictoryAxis style={axisStyle} />
            </VictoryChart>

            <ChartTimeButton defaultValue={chartTime} onChange={setChartTime} />
          </div>
        </>
      );
    }
    return null;
  };

  return (
    <Styles className={`InvestmentDiagram ${className}`}>
      <ProfileHeader />
      {isAnonymous ? null : (
        <Button
          color="white"
          className="InvestmentDiagram__button"
          onClick={() => push("/profile/movements")}
          isLoading={false}
          disabled={status === "loading"}
        >
          <MovementsSVG />
          Ver movimientos
        </Button>
      )}
      <div className="InvestmentDiagram__container">
        {isAnonymous ? emptyNode : renderState()}
      </div>
    </Styles>
  );
};

InvestmentDiagram.defaultProps = {
  className: ""
};

export default InvestmentDiagram;
