import React from "react";
import { useFetchUser } from "@anefi/services";

import Briefcase from "../Briefcase/Briefcase";
import InvestmentDiagram from "../InvestmentDiagram/InvestmentDiagram/InvestmentDiagram";
import Recommendations from "../Recommendations/Recommendations/Recommendations";
import Record from "../Record/Record/Record";
import Styles from "./Home.styles";
import { HomeProps as Props } from "./Home.types";
import Footer from "components/global/Footer/Footer";
import Navbar from "components/global/Navbar/Navbar";
import useAuth from "contexts/auth/auth.context.hooks";

import BackgroundImageSVG from "../../../../public/assets/images/anefi/background-image.svg";

const Home: React.FC<Props> = props => {
  const { isAnonymous } = useAuth();
  const auth = useAuth();
  const { data: user } = useFetchUser(auth);
  const { additionalInfo } = user ?? {};

  return (
    <Styles className="Home">
      <header className="Home__header">
        <Navbar />
        <div className="Home__images">
          <BackgroundImageSVG />
        </div>
      </header>
      <main className="Home__main">
        <InvestmentDiagram className="Home__investment" />
        <Record className="Home__record" />
        <Recommendations className="Home__recommendations" />
        {isAnonymous || !additionalInfo ? null : (
          <Briefcase className="Home__briefcase" />
        )}
      </main>
      <footer className="Home__footer">
        <Footer />
      </footer>
    </Styles>
  );
};

Home.defaultProps = {};

export default Home;
