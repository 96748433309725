import React, { useEffect } from "react";
import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";
import { useFetchUser } from "@anefi/services";

import ProfileHeaderPlaceholder from "./ProfileHeader.placeholder";
import Styles from "./ProfileHeader.styles";
import { ProfileHeaderProps as Props } from "./ProfileHeader.types";
import useAuth from "contexts/auth/auth.context.hooks";
import variables from "styles/util/variables";
import Button from "components/global/Button/Button";

const ProfileHeader: React.FC<Props> = props => {
  const { className } = props;
  const auth = useAuth();
  const { isAnonymous } = auth;
  const fetchedUser = useFetchUser(auth);
  const { data: user, status, refetch, isLoading } = fetchedUser;
  const { error } = fetchedUser;

  const handleError = () => {
    refetch();
  };

  useEffect(() => {
    if (Capacitor.getPlatform() === "web") return;

    const isIOS = Capacitor.getPlatform() === "ios";
    const isAndroid = Capacitor.getPlatform() === "android";

    if (isAndroid) {
      StatusBar.setBackgroundColor({
        color: variables.palette["black-s0-l10"]
      });
    }

    if (isIOS || isAndroid) {
      StatusBar.setStyle({ style: Style.Dark });
    }

    return () => {
      StatusBar.setStyle({ style: Style.Light });

      if (isAndroid) {
        StatusBar.setBackgroundColor({ color: variables.palette.white });
      }
    };
  }, []);

  const renderStates = () => {
    if (status === "loading" || status === "idle")
      return <ProfileHeaderPlaceholder />;
    if (status === "error") {
      const { message } = error ?? {};
      if (message === "No user for uid") {
        return <p className="ProfileHeader__name">¡Hola!</p>;
      }

      return (
        <div className="ProfileHeader__error">
          <p>¡Oops! Hubo un problema al cargar los datos</p>
          <Button type="LINK" onClick={handleError} isLoading={isLoading}>
            Intentar nuevamente
          </Button>
        </div>
      );
    }
    if (status === "success") {
      return (
        user && (
          <>
            <p className="ProfileHeader__email">{user.email}</p>
            <p className="ProfileHeader__name">Hola, {user.name}</p>
          </>
        )
      );
    }
    return null;
  };

  return (
    <Styles className={`ProfileHeader ${className}`}>
      {isAnonymous ? (
        <p className="ProfileHeader__name">¡Hola!</p>
      ) : (
        renderStates()
      )}
    </Styles>
  );
};

ProfileHeader.defaultProps = {
  className: ""
};

export default ProfileHeader;
