import React from "react";

import { RecordProductRowPlaceholderStyled as Styles } from "./RecordProductRow.styles";
import { RecordProductRowPlaceholderProps as Props } from "./RecordProductRow.types";

const RecordProductRowPlaceholder: React.FC<Props> = props => {
  return (
    <Styles className="RecordProductRowPlaceholder ">
      <div className="RecordProductRowPlaceholder__content loading-shine" />
    </Styles>
  );
};

RecordProductRowPlaceholder.defaultProps = {};

export default RecordProductRowPlaceholder;
