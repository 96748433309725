import styled from "styled-components";

import { HomeStyledProps as Props } from "./Home.types";
import { CONSTANTS } from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { desktop, tablet, mobile } = BREAKPOINTS;

const HomeStyled = styled.div<Props>`
  min-width: var(--sizes-page-minWidth);
  max-width: var(--sizes-page-maxWidth);
  min-height: var(--sizes-page-minHeight);
  background-color: var(--palette-white);
  display: grid;
  grid-template-columns: var(--sizes-page-columns);
  grid-template-rows: max-content 1fr max-content;
  padding-top: 8rem;

  @media (max-width: ${mobile}px) {
    background-color: var(--palette-gray-s0-l97);
  }

  .Home {
    &__header {
      grid-column: fullBleed;
    }

    &__main {
      flex: 1;
      display: grid;
      grid-template-columns: 44.4rem 1fr;
      grid-template-areas:
        "investment record"
        "investment recommendations"
        "investment briefcase";
      column-gap: 2.2rem;
      row-gap: 3.2rem;
      grid-column: content;
      padding-bottom: 10rem;

      @media (max-width: ${desktop}px) {
        padding-top: calc(
          var(--sizes-navbar-height-mobile) - env(safe-area-inset-top)
        );
        grid-template-columns: 1fr;
        grid-template-areas:
          "investment"
          "record"
          "recommendations"
          "briefcase";
      }

      @media (max-width: ${mobile}px) {
        padding-top: 0;
      }
    }

    &__investment {
      grid-area: investment;
      position: fixed;
      padding-top: 3.6rem;
      z-index: 0;

      @media (max-width: ${desktop}px) {
        position: unset;
      }
    }

    &__record {
      margin-top: 34rem;

      @media (max-width: ${tablet}px) {
        margin-top: 1.6rem;
      }

      grid-area: record;
      border: 0.1rem solid var(--palette-gray-s0-l85);
      background-color: var(--palette-white);
      border-radius: 1.2rem;
      padding: 2.8rem;

      @media (max-width: ${desktop}px) {
        margin-top: 0;
      }
    }

    &__recommendations {
      grid-area: recommendations;
      overflow: hidden;
      padding: 2.8rem;
      background-color: var(--palette-white);
      border-radius: 1.2rem;
      border: 0.1rem solid var(--palette-gray-s0-l85);

      @media (max-width: ${mobile}px) {
        padding: 2rem;
      }
    }

    &__briefcase {
      grid-area: briefcase;
      background-color: var(--palette-white);
      padding: 2.8rem;
      border-radius: 1.2rem;
      border: 0.1rem solid var(--palette-gray-s0-l85);
      margin-bottom: 12rem;
    }

    &__images {
      width: 100%;
      background-color: var(--palette-gray-s0-l97);
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      position: absolute;

      @media (max-width: ${mobile}px) {
        display: none;
      }
    }

    &__footer {
      grid-column: fullBleed;
      padding-top: 3.2rem;
    }
  }
`;

export default HomeStyled;
