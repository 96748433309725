import Head from "next/head";
import React from "react";

import TabsMenu from "components/global/TabsMenu/TabsMenu";
import Home from "components/home/Home/Home";
import useI18n from "hooks/useI18n";

interface Props {}

const HomePage = (props: Props) => {
  const t = useI18n();

  return (
    <>
      <Head>
        <title>{t.home.metaTitle}</title>
        <meta name="description" content={t.home.metaDescription} />
        <meta name="robots" content="index, follow" />
      </Head>
      <TabsMenu>
        <Home />
      </TabsMenu>
    </>
  );
};

// export const getStaticProps: GetStaticProps = async context => {
//   const queryClient = new QueryClient();

//   try {
//     // Banners
//     const banners = prefetchBanners(queryClient, "simple_commerce_carousel");

//     // Default store
//     await prefetchDefaultStore(queryClient);
//     const defaultStore = queryClient.getQueryData<Store>(["stores", "default"]);

//     // Categories
//     const categories = prefetchCategories(queryClient, defaultStore!.storeId);

//     // Categories with products
//     const categoriesWithProducts = prefetchCategoriesWithProducts(queryClient, {
//       storeId: defaultStore!.storeId,
//       productsByGroup: PRODUCTS_PER_CATEGORY_REQUEST,
//       size: CATEGORIES_PER_REQUEST
//     });

//     await Promise.all([banners, categories, categoriesWithProducts]);
//   } catch (e) {
//     Bugsnag.notify({
//       ...e,
//       name: "Prefetch banners, categories and categories with products"
//     });
//     console.error(e.message);
//   }

//   return {
//     props: {
//       dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient)))
//     },
//     revalidate: 60 * 30
//   };
// };

export default HomePage;
