export const chartTimeData = [
  {
    key: "1S",
    value: 7
  },
  {
    key: "1M",
    value: 30
  },
  {
    key: "3M",
    value: 90
  },
  {
    key: "6M",
    value: 180
  },
  {
    key: "1A",
    value: 364
  }
];
