import React from "react";

import { ProfileHeaderPlaceholderStyled as Styles } from "./ProfileHeader.styles";
import { ProfileHeaderPlaceholderProps as Props } from "./ProfileHeader.types";

const ProfileHeaderPlaceholder: React.FC<Props> = props => {
  return (
    <Styles className="ProfileHeaderPlaceholder">
      <div className="ProfileHeaderPlaceholder__name loading-shine" />
      <div className="ProfileHeaderPlaceholder__email loading-shine" />
    </Styles>
  );
};

ProfileHeaderPlaceholder.defaultProps = {};

export default ProfileHeaderPlaceholder;
