import { DailyBalance } from "@anefi/types";
import dayjs from "dayjs";

export const transformBalanceToGraphData = (
  dailyBalances: DailyBalance[] | undefined
) => {
  if (!dailyBalances) return;

  const data = dailyBalances.map((dailyBalance, index) => {
    return {
      y: dailyBalance?.balance ?? 0,
      x: dayjs(dailyBalance.date).format("DD/MM")
    };
  });

  return data;
};

export const getTickCount = (dailyBalances: DailyBalance[] | undefined) => {
  if (!dailyBalances) return 1;

  const balances = dailyBalances.map(dailyBalance => {
    return dailyBalance?.balance ?? 0;
  });

  const min = Math.round(Math.min(...balances));
  const max = Math.round(Math.max(...balances));

  if (min === max) return 1;
};

export const getBalancesLabel = (dailyBalances: DailyBalance[] | undefined) => {
  if (!dailyBalances) return;

  const balances = dailyBalances.map(dailyBalance => {
    return dailyBalance?.balance ?? 0;
  });

  const min = Math.round(Math.min(...balances));
  const max = Math.round(Math.max(...balances));

  if (min === max) return;

  const step = (max - min) / 4;

  const labels = [
    min + step * 4,
    min + step * 3,
    min + step * 2,
    min + step * 1,
    min
  ];

  return labels;
};

export const axisStyle = {
  axis: {
    stroke: "var(--palette-gray-s0-l97)"
  },
  tickLabels: {
    fill: "var(--palette-gray-s0-l50)"
  }
};

export const lineStyle = {
  data: {
    stroke: "var(--palette-secondary-s100-l35)",
    strokeWidth: 2
  }
};

export const scatterStyle = {
  data: { fill: "var(--palette-secondary-s100-l35)" }
};
