import styled from "styled-components";

import { ChartTimeButtonStyledProps as Props } from "./ChartTimeButton.types";

const ChartTimeButtonStyled = styled.div<Props>`
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;

  .ChartTimeButton {
    &__text {
      color: var(--palette-primary);
      font-size: 1.6rem;
      font-weight: 600;
      cursor: pointer;
      border-radius: 50%;
      padding: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__selected {
      color: var(--palette-white);
      background-color: var(--palette-primary);
    }
  }
`;

export default ChartTimeButtonStyled;
