import React from "react";

import Button from "../Button/Button";
import Styles from "./EmptyState.styles";
import { EmptyStateProps as Props } from "./EmptyState.types";

const EmptyState: React.FC<Props> = props => {
  const { icon, title, message, buttonOptions, className } = props;
  const { title: buttonTitle, action } = buttonOptions;
  const { color = "primary", type = "FILLED" } = buttonOptions;

  return (
    <Styles className={`EmptyState ${className}`}>
      {icon}
      <h3 className="EmptyState__title">{title}</h3>
      <p className="EmptyState__message">{message}</p>
      <Button onClick={action} color={color} type={type}>
        {buttonTitle}
      </Button>
    </Styles>
  );
};

EmptyState.defaultProps = {};

export default EmptyState;
