import router from "next/router";
import React from "react";
import { useFetchPortfolios } from "@anefi/services";

import AnefiCard from "../AnefiCard/AnefiCard";
import Styles from "./Briefcase.styles";
import { BriefcaseProps as Props } from "./Briefcase.types";
import Button from "components/global/Button/Button";
import useAuth from "contexts/auth/auth.context.hooks";

const Briefcase: React.FC<Props> = props => {
  const { className } = props;
  const { push } = router;
  const auth = useAuth();
  const fetchedPortfolios = useFetchPortfolios(auth);
  const { data: portfolios, refetch } = fetchedPortfolios;
  const { status: statusBriefcase } = fetchedPortfolios;

  return (
    <Styles className={`Briefcase ${className}`}>
      <AnefiCard
        title="Portafolios"
        link="products"
        founds={false}
        itemsBriefcase={portfolios}
        statusBriefcase={statusBriefcase}
        refetchBriefcase={refetch}
      />
      <Button
        type="BORDER"
        onClick={() => {
          push("/products");
        }}
        className="Briefcase__button"
      >
        Ver todos los portafolios
      </Button>
    </Styles>
  );
};

Briefcase.defaultProps = {
  className: ""
};

export default Briefcase;
