import styled from "styled-components";

import { RecordStyledProps as Props } from "./Record.types";

const RecordStyled = styled.div<Props>`
  .Record {
    &__title {
      color: var(--palette-black);
      font-size: 2.2rem;
      font-weight: 600;
      padding-bottom: 2.6rem;
    }

    &__titles {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-items: center;
      gap: 1rem;
      padding-bottom: 2.4rem;
      font-size: 1.2rem;
      color: var(--palette-gray-s0-l50);
    }
  }
`;

export default RecordStyled;
