import styled from "styled-components";

import { BriefcaseStyledProps as Props } from "./Briefcase.types";

const BriefcaseStyled = styled.div<Props>`
  .Briefcase {
    &__button {
      margin-top: 3.2rem;
    }
  }
`;

export default BriefcaseStyled;
