import styled from "styled-components";

import { RecordProductRowStyledProps as Props } from "./RecordProductRow.types";
import { RecordProductRowPlaceholderProps } from "./RecordProductRow.types";

const RecordProductRowStyled = styled.div<Props>`
  padding: 1rem;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 0.1rem solid var(--palette-gray-s0-l85);
  }

  .RecordProductRow {
    &__company {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    &__image {
      padding: 0.8rem;
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.8rem;
    }

    &__amount {
      display: flex;
      flex-direction: column;
      gap: 0.6rem;
    }

    &__name {
      color: var(--palette-black);
      font-size: 1.6rem;
      font-weight: 600;
    }

    &__plan {
      color: var(--palette-gray-s0-l50);
    }

    &__record {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-items: center;
      gap: 1rem;
      margin-top: 1.6rem;

      &__black {
        color: var(--palette-);
        font-size: 1.4rem;
        font-weight: 600;
      }

      &__green {
        color: var(--palette-secondary-s100-l35);
        font-size: 1.4rem;
        font-weight: 600;
      }

      &__red {
        color: var(--palette-red-s60-l60);
        font-size: 1.4rem;
        font-weight: 600;
      }
    }
  }
`;

export const RecordProductRowPlaceholderStyled = styled.div<RecordProductRowPlaceholderProps>`
  display: flex;

  .RecordProductRowPlaceholder {
    &__content {
      flex: 1;
      height: 8rem;
      border-radius: 1rem;
    }
  }
`;

export default RecordProductRowStyled;
