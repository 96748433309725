import styled from "styled-components";

import { EmptyStateStyledProps as Props } from "./EmptyState.types";

const EmptyStateStyled = styled.div<Props>`
  display: grid;
  place-items: center;

  .EmptyState {
    &__title {
      color: var(--palette-primary);
      text-align: center;
      font-weight: 500;
      font-size: 2.8rem;
      padding-top: 3.2rem;
    }

    &__message {
      color: var(--palette-gray-s0-l50);
      font-size: 1.2rem;
      text-align: center;
      padding-top: 0.8rem;
      padding-bottom: 2.4rem;
    }
  }
`;

export default EmptyStateStyled;
