import React, { useState } from "react";
import { chartTimeData } from "./ChartTimeButton.helpers";

import Styles from "./ChartTimeButton.styles";
import { ChartTimeButtonProps as Props } from "./ChartTimeButton.types";

const ChartTimeButton: React.FC<Props> = props => {
  const { className, defaultValue, onChange } = props;
  const [localSelect, setLocalSelect] = useState(defaultValue);

  const changeHandler = (value: number) => {
    setLocalSelect(value);
    onChange(value);
  };

  return (
    <Styles className={`ChartTimeButton ${className}`}>
      {chartTimeData.map(data => {
        const { value, key } = data;
        const isSelected = localSelect === value;

        return (
          <p
            onClick={() => changeHandler(value)}
            key={key}
            className={`ChartTimeButton__text ${
              isSelected ? " ChartTimeButton__selected" : ""
            }`}
          >
            {key}
          </p>
        );
      })}
    </Styles>
  );
};

ChartTimeButton.defaultProps = {
  className: ""
};

export default ChartTimeButton;
