import styled from "styled-components";

import { ProfileHeaderStyledProps as Props } from "./ProfileHeader.types";
import { ProfileHeaderPlaceholderProps } from "./ProfileHeader.types";
import { CONSTANTS } from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet, mobile } = BREAKPOINTS;

const ProfileHeaderStyled = styled.div<Props>`
  .ProfileHeader {
    &__error {
      display: flex;
      align-items: center;
    }

    &__name {
      font-weight: 500;
      font-size: 3.2rem;
      line-height: 4rem;
      color: var(--palette-black-s0-l10);
      padding-top: 0.8rem;
      padding-bottom: 2.4rem;
    }

    &__email {
      font-size: 1.2rem;
      color: var(--palette-gray-s0-l40);
      line-height: 1.2rem;
    }
  }
`;

export const ProfileHeaderPlaceholderStyled = styled.div<ProfileHeaderPlaceholderProps>`
  @media (max-width: ${tablet}px) {
    align-self: center;
    margin-left: 1.6rem;
  }

  @media (max-width: ${mobile}px) {
    margin-left: 1.2rem;
  }

  .ProfileHeaderPlaceholder {
    &__name {
      height: 3.6rem;
      width: 40rem;
      margin-top: 4rem;
      margin-bottom: 1.2rem;

      @media (max-width: ${tablet}px) {
        height: 1.6rem;
        width: 13.6rem;
        margin: 0;
        margin-bottom: 0.8rem;
      }
    }

    &__email {
      height: 1.6rem;
      width: 32rem;

      @media (max-width: ${tablet}px) {
        width: 11.2rem;
        height: 1.2rem;
      }
    }
  }
`;

export default ProfileHeaderStyled;
